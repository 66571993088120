<template>
  <div>

    <b-row class="mb-8" v-if="page.html && page.html !== ''">
      <b-col lg="12">
        <div v-html="page.html"></div>
      </b-col>
    </b-row>
    <template v-for="(term, i) in terms">
      <TermsCheckbox
        class="my-2"
        v-bind:key="i"
        :term="term"
        @selected="termAccepted"
        @showTerm="showTerm"
      />
    </template>
    <b-row v-if="allocations.length > 0">
      <b-form-group class="col-6" id="company-group" :label="$t('PAGES.REGISTRATION.SELECTED_COMPANIES')" label-for="company">
        <b-form-input class="mb-4" v-for="c in allocations" :key="c.id" id="company" v-model="c.name" placeholder="" disabled />
      </b-form-group>
    </b-row>

    <div v-if="local_page_data && local_page_data.data && local_page_data.data.details">
      <b-row v-show="local_page_data.data.details.firstname">
        <b-form-group
          v-show="local_page_data.data.details.firstname"
          class="col-md-6 col-sm-12"
          id="firstname-group"
          :label="$t('MEMBER.FIRSTNAME')"
          label-for="firstname"
        >
          <b-form-input
            id="firstname"
            v-model="local_page_data.data.details.firstname"
            :placeholder="$t('MEMBER.FIRSTNAME')"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-show="local_page_data.data.details.lastname"
          class="col-md-6 col-sm-12"
          id="lastname-group"
          :label="$t('MEMBER.LASTNAME')"
          label-for="lastname"
        >
          <b-form-input
            id="lastname"
            v-model="local_page_data.data.details.lastname"
            :placeholder="$t('MEMBER.FIRSTNAME')"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-row>

      <b-row>
        <b-form-group
          v-show="local_page_data.data.details.phone"
          class="col-6"
          id="phone-group"
          :label="$t('MEMBER.PHONE')"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="local_page_data.data.details.phone"
            :placeholder="$t('MEMBER.PHONE')"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-show="local_page_data.data.details.email"
          class="col-6"
          id="email-group"
          :label="$t('MEMBER.EMAIL')"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="local_page_data.data.details.email"
            :placeholder="$t('MEMBER.EMAIL')"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-row>

      <b-row>
        <b-form-group
          v-show="local_page_data.data.details.birthdate"
          class="col-6"
          id="birthdate-group"
          :label="$t('MEMBER.DOB')"
          label-for="birthdate"
        >
          <b-form-input
            id="birthdate"
            v-model="local_page_data.data.details.birthdate"
            :placeholder="$t('MEMBER.DOB_INFO')"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-row>

      <b-row>
        <b-form-group
          v-show="local_page_data.data.details.address"
          class="col-6"
          id="address-group"
          :label="$t('MEMBER.ADDRESS')"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="local_page_data.data.details.address"
            :placeholder="$t('MEMBER.ADDRESS')"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-show="local_page_data.data.details.zipcode"
          class="col-6"
          id="zipcode-group"
          :label="$t('MEMBER.ZIPCODE')"
          label-for="zipcode"
        >
          <b-form-input
            id="zipcode"
            v-model="local_page_data.data.details.zipcode"
            placeholder="00000"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-row>

      <b-row>
        <b-form-group
          v-show="local_page_data.data.details.region"
          class="col-6"
          id="region-group"
          :label="$t('MEMBER.REGION')"
          label-for="region"
        >
          <b-form-input
            id="region"
            v-model="local_page_data.data.details.region"
            placeholder=""
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          v-show="local_page_data.data.details.commune"
          class="col-6"
          id="commune-group"
          :label="$t('MEMBER.COMMUNE')"
          label-for="commune"
        >
          <b-form-input
            id="commune"
            v-model="local_page_data.data.details.commune"
            placeholder=""
            disabled
          ></b-form-input>
        </b-form-group>
      </b-row>
    </div>

    <b-row v-if="local_page_data && local_page_data.data && local_page_data.data.personnr">
      <b-form-group class="col-6" id="personnr-group" :label="$t('MEMBER.PERSONNR')" label-for="personnr">
        <b-form-input
          id="personnr"
          v-model="local_page_data.data.personnr"
          placeholder=""
          disabled
        ></b-form-input>
      </b-form-group>
    </b-row>

    <b-modal ref="show-term" size="lg" :title="selected_term.name">
      <p class="my-4" :html="selected_term.template" v-html="selected_term.template"></p>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import TermsCheckbox from './terms/TermsCheckbox.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ConfirmRegisterPage',
  props: ['company', 'page_data', 'order', 'page', 'requirements'],
  emits: ['update:page_data', 'wizardPageWasUpdated', 'isAllPolicyChecked'],
  components: {
    TermsCheckbox,
  },
  mixins: [ toasts ],
  watch: {
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
  },
  data() {
    return {
      local_page_data: {},
      // allocations: [],
      accepted: [],
      terms: [],
      selected: [],
      visable_modal: false,
      current_policy: {},
      selected_term: {
        name: 'none',
        template: ''
      }
    };
  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data }
    }
    this.loadTerms();

    this.formWasUpdated();
  },
  computed: {
    ...mapGetters(['memberCompanies']),
    allocations() {
      if (!this.local_page_data || !this.local_page_data.data || !this.local_page_data.data.area_alloc) return [];

      return this.local_page_data.data.area_alloc.company_ids.map(a => ({
        id: a,
        name: this.memberCompanies.find(c => c.company_id === a).name
      }))
    },
  },
  methods: {
    getData() {
      return {
        is_accepted: false
      }
    },
    formWasUpdated() {
      this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
    },
    isNextButtonOK() {
      let valid = true;

      const mand = this.accepted.filter(item => item.is_mandatory);
      const acc = mand.filter(item => !item.isAccepted);

      valid = acc.length === 0;
      return valid;
    },
    loadTerms() {
      axios
        .get(`/company/terms/${this.company.company_id}`)
        .then(res => {
          this.terms = res.data;
          this.terms = this.terms.filter(item => item.audience ? item.audience === 'PUBLIC' : true);
          this.terms = this.terms.filter(item => item.is_active);

          for (const t of this.terms) {
            this.accepted.push({ id: t.id, isAccepted: false, is_mandatory: t.is_mandatory });
          }

          this.formWasUpdated();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.REGISTRATION.UNABLE_GET_TERMS'));
        });
    },
    gotoPayment(e, method) {
      e.preventDefault();
    },

    onClick($event) {
      this.$emit('isAllPolicyChecked', this.selected.length == this.terms.length);
    },

    termAccepted(id, isAccepted) {
      for (let i = 0; i < this.accepted.length; ++i) {
        if (this.accepted[i].id === id) {
          this.accepted[i].isAccepted = isAccepted;
          break;
        }
      }

      let valid = true;

      for (const t of this.accepted) {
        if (t.is_mandatory) {
          if (!t.isAccepted) {
            valid = false;
            break;
          }
        }
      }

      this.local_page_data[this.page.id] = {
        ...this.local_page_data[this.page.id],
        validation: { $invalid: !valid }
      };

      this.$emit('update:page_data', this.local_page_data);

      this.formWasUpdated();
    },

    showTerm(id) {
      for (const t of this.terms) {
        if (t.id === id) {
          this.showModal(t);
          break;
        }
      }
    },

    showModal(term) {
      this.selected_term = term;

      this.$refs['show-term'].show();
    },

  }
};
</script>
