<template>
  <b-modal ref="alreadyTakenModal" no-close-on-backdrop hide-footer @hide="on_hide" :title="title">
    <b-row>
      <b-col lg="12">
        <div class="d-block text-center">
          <h3>{{ message }}</h3>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-8 mb-8">
      <b-col lg="6">

        <b-button v-if="!rate_limit" class="" :variant="confirmColor" block @click="toLoginClicked">{{$t('ACCOUNT.TO_LOGIN')}}</b-button>
      </b-col>
      <b-col lg="6">
        <b-button class="" variant="outline-secondary" block @click="onCancel"
          >{{$t('COMMON.CANCEL')}}</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'already-taken-popup',
  data() {
    return {
        type: 'primary',
        title: this.$t('PAGES.REGISTRATION.UNABLE_PROCEED')
    };
  },
  mixins: [ toasts ],
  props: ['email_taken','personnr_taken','rate_limit'],
  emits: ['toLoginClicked', 'confirm', 'cancel'],
  async mounted() {},
  methods: {
    toLoginClicked() {
      this.$emit('toLoginClicked');
    },
    show() {
      this.$refs['alreadyTakenModal'].show();
    },
    onConfirm() {
      this.$refs['alreadyTakenModal'].hide();

      this.$emit('confirm');
    },
    on_hide() {
      this.$emit('cancel');
    },
    onCancel() {
      this.$refs['alreadyTakenModal'].hide();

      this.$emit('cancel');
    },
  },
  computed: {
    message() {
        return this.rate_limit ? this.$t('PAGES.REGISTRATION.TOO_MANY_LOOKUPS') : (this.email_taken ? this.$t('PAGES.REGISTRATION.EMAIL_TAKEN') : this.$t('PAGES.REGISTRATION.PERSONNR_TAKEN'));
    },
    confirmColor(){
      switch (this.type) {
        case 'danger':
          return 'outline-danger'
        case 'success':
          return 'outline-success'
        case 'info':
          return 'outline-info'
        case 'warning':
          return 'outline-warning'
        case 'primary':
          return 'outline-primary'
      }
    }
  }
};
</script>
